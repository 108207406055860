import "./ImagePromptGenerator.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function EndGen() {
  const [apiKey, setApiKey] = useState(localStorage.getItem("apiKey") || "");
  const [songTopic, setSongTopic] = useState("");
  const [generatedLyrics, setGeneratedLyrics] = useState("");
  const [numScenes, setNumScenes] = useState("1");
  const [illustrationStyle, setIllustrationStyle] = useState("");
  const [imagePrompts, setImagePrompts] = useState([]);
  const [uploadedImages, setUploadedImages] = useState(Array.from({ length: numScenes }, () => null));
  const [animationPrompts, setAnimationPrompts] = useState(Array.from({ length: numScenes }, () => ""));
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("apiKey", apiKey);
  }, [apiKey]);

  useEffect(() => {
    setUploadedImages(Array.from({ length: numScenes }, () => null));
    setAnimationPrompts(Array.from({ length: numScenes }, () => ""));
  }, [numScenes]);

  const generateLyrics = async () => {
    if (!songTopic) {
      alert("Please enter a topic for the song.");
      return;
    }
    try {
      const response = await fetch("/api/generateLyrics", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ topic: songTopic, apiKey }),
      });
      if (!response.ok) throw new Error("Failed to generate lyrics.");
      const data = await response.json();
      setGeneratedLyrics(data.lyrics);
    } catch (error) {
      console.error(error);
      alert("Error generating lyrics. Try again.");
    }
  };

  const generateImagePrompts = async () => {
    if (!generatedLyrics || !numScenes || !illustrationStyle) {
      alert("Please generate lyrics and fill in all fields.");
      return;
    }
    try {
      const response = await fetch("/api/generateScene", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          lyrics: generatedLyrics, 
          numScenes: parseInt(numScenes), 
          illustrationStyle, 
          apiKey 
        }),
      });

      if (!response.ok) throw new Error("Failed to generate image prompts.");
      
      const data = await response.json();

      if (data.imagePrompts && Array.isArray(data.imagePrompts)) {
        setImagePrompts(data.imagePrompts.map(scene => scene.trim()));
      } else {
        console.error("Invalid data received:", data);
      }

    } catch (error) {
      console.error("Error in generateImagePrompts:", error);
      alert("Failed to generate image prompts. Please try again.");
    }
  };

  const handleImageUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const updatedImages = [...uploadedImages];
      updatedImages[index] = file;
      setUploadedImages(updatedImages);
    }
  };

  const handleGeneratePrompt = async (index) => {
    if (!uploadedImages[index]) {
      alert("Please upload an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("image", uploadedImages[index]);
    formData.append("apiKey", apiKey);
    formData.append("imagePrompt", imagePrompts[index] || "");

    try {
      const response = await fetch("https://www.thequest.ai/api/multiProcessImg", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to process the image.");
      }

      const data = await response.json();
      const updatedPrompts = [...animationPrompts];
      updatedPrompts[index] = data.prompt;
      setAnimationPrompts(updatedPrompts);
    } catch (error) {
      console.error("Error generating animation prompt:", error);
      alert("Error generating the prompt. Please try again.");
    }
  };

  return (
    <div className="app-container2">
      <button className="roll-button2" onClick={() => navigate("/options")}>
        Go Back
      </button>
      <div className="api-key-section">
        <label>OpenAI API Key:</label>
        <input
          type="password"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          placeholder="Enter your API key"
          className="api-key-input"
        />
      </div>

      {/* Generate Song Section */}
      <div className="card3">
        <h2>Generate a Song</h2>
        <input
          type="text"
          value={songTopic}
          onChange={(e) => setSongTopic(e.target.value)}
          placeholder="Enter a topic..."
        />
        <button onClick={generateLyrics}>Generate Lyrics</button>

        {generatedLyrics && (
          <textarea
            value={generatedLyrics}
            onChange={(e) => setGeneratedLyrics(e.target.value)}
          />
        )}
      </div>

      {/* Generate Image Prompts Section */}
      <h2>Generate Image Prompts</h2>
      <label>Number of Scenes:</label>
      <select value={numScenes} onChange={(e) => setNumScenes(e.target.value)}>
        {[...Array(10).keys()].map((i) => (
          <option key={i + 1} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </select>

      <label>Illustration Style:</label>
      <input
        type="text"
        value={illustrationStyle}
        onChange={(e) => setIllustrationStyle(e.target.value)}
        placeholder="Enter illustration style"
      />
      <button onClick={generateImagePrompts}>Generate Image Prompts</button>

      {imagePrompts.length > 0 ? (
        imagePrompts.map((prompt, index) => (
          <textarea
            key={index}
            value={prompt}
            onChange={(e) => {
              const newPrompts = [...imagePrompts];
              newPrompts[index] = e.target.value;
              setImagePrompts(newPrompts);
            }}
            rows={4}
            style={{ width: "100%", marginBottom: "10px" }}
          />
        ))
      ) : (
        <p>No image prompts generated yet.</p>
      )}

      {/* Upload Images for Animation Prompts */}
      <div>
      <h2>Upload Images for Animation Prompts</h2>
      <label>Number of Scenes:</label>
      <select value={numScenes} onChange={(e) => setNumScenes(Number(e.target.value))}>
        {[1, 2, 3, 4, 5].map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>

      {Array.from({ length: numScenes }).map((_, index) => (
        <div key={index} className="image-upload-section">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(index, e)}
          />
          {uploadedImages[index] && (
            <img
              src={URL.createObjectURL(uploadedImages[index])}
              alt={`Preview ${index + 1}`}
              className="preview-image"
            />
          )}
          <button onClick={() => handleGeneratePrompt(index)}>Generate Animation Prompt</button>

          {animationPrompts[index] && (
            <textarea
              value={animationPrompts[index]}
              readOnly
              className="generated-prompt"
            />
          )}
        </div>
      ))}
    </div> </div>
  );
}

export default EndGen;
