import React, { useState, useEffect } from "react";
import "./TextToSpeech.css"; // Link to a new CSS file for styling
import { useNavigate } from "react-router-dom";

function TextToSpeech() {
    const [text, setText] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [apiKey, setApiKey] = useState('');
    const [voice, setVoice] = useState('alloy');
    const [storyPrompt, setStoryPrompt] = useState('');
    const [story, setStory] = useState('');
    const [storyLoading, setStoryLoading] = useState(false);
    const [topicPrompt, setTopicPrompt] = useState('');
    const [topics, setTopics] = useState([]);
    const [topicLoading, setTopicLoading] = useState(false);
    const navigate = useNavigate();

  const voices = ["nova", "shimmer", "echo", "onyx", "fable", "alloy", "ash", "coral","sage"];

  useEffect(() => {
    const savedApiKey = localStorage.getItem("apiKey");
    if (savedApiKey) setApiKey(savedApiKey);
  }, []);

  const handleGenerateSpeech = async () => {
    setLoading(true);
    setError(null);
    setAudioUrl(null);
  
    try {
      const response = await fetch("/api/generateSpeech", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text, apiKey, voice }),
      });
  
      if (!response.ok) throw new Error(await response.text());
  
      const { audioBase64 } = await response.json();
      if (audioBase64) {
        setAudioUrl(`data:audio/mp3;base64,${audioBase64}`);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  

  const handleGenerateStory = async () => {
    setStoryLoading(true);
    setError(null);
    setStory("");

    try {
      const response = await fetch("/api/generateStory", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ prompt: storyPrompt, apiKey }),
      });

      if (!response.ok) throw new Error(await response.text());

      const { story } = await response.json();
      setStory(story);
      //setText(story);
    } catch (err) {
      setError(err.message);
    } finally {
      setStoryLoading(false);
    }
  };

  const handleGenerateTopic = async () => {
    setTopicLoading(true);
    setError(null);
    setTopics([]);

    try {
      const response = await fetch("/api/generateTopic", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ prompt: topicPrompt, apiKey }),
      });

      if (!response.ok) throw new Error(await response.text());

      const { topics } = await response.json();
      setTopics(topics);
    } catch (err) {
      setError(err.message);
    } finally {
      setTopicLoading(false);
    }
  };

  return (
    <div className="app-container">
      <button className="roll-button2" onClick={() => navigate("/options")}>
        Go Back
      </button>
      <h2>OpenAI API Key</h2>
      <input
        type="text"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        placeholder="Enter your API key"
        className="input-field"
      />

      <div className="section">
        <h2>Generate a Topic</h2>
        <textarea
          value={topicPrompt}
          onChange={(e) => setTopicPrompt(e.target.value)}
          placeholder="Enter a topic prompt (e.g., 'Ideas for stories for children aged 4–8')"
          className="input-textarea"
        />
        <button
          onClick={handleGenerateTopic}
          disabled={topicLoading}
          className="action-button"
        >
          {topicLoading ? "Generating Topics..." : "Generate Topics"}
        </button>
        {topics.length > 0 && (
          <div className="story-output">
            <h3>Generated Topics:</h3>
            <ul>
              {topics.map((topic, index) => (
                <li key={index}>{topic}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="section">
        <h2>Generate a Children's Story</h2>
        <textarea
          value={storyPrompt}
          onChange={(e) => setStoryPrompt(e.target.value)}
          placeholder="Enter a story prompt (e.g., 'A brave squirrel and a magic tree')"
          className="input-textarea"
        />
        <button
          onClick={handleGenerateStory}
          disabled={storyLoading}
          className="action-button"
        >
          {storyLoading ? "Generating Story..." : "Generate Story"}
        </button>
        {story && (
          <div className="story-output">
            <h3>Your Story:</h3>
            {story.split("\n\n").map((paragraph, index) => (
              <p key={index} className="story-paragraph">{paragraph}</p>
            ))}
          </div>
        )}
      </div>

      <div className="section">
        <h2>Text to Speech</h2>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Type or paste text here..."
          className="input-textarea"
        />
        <h2>Voice Selection</h2>
        <select
          value={voice}
          onChange={(e) => setVoice(e.target.value)}
          className="input-select"
        >
          {voices.map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>

        <button
          onClick={handleGenerateSpeech}
          disabled={loading}
          className="action-button"
        >
          {loading ? "Generating Speech..." : "Generate Speech"}
        </button>
        {error && <p className="error-message">{error}</p>}
        {audioUrl && (
          <div className="audio-output">
            <audio controls src={audioUrl} />
            <a href={audioUrl} download className="download-link">
              Download Audio
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default TextToSpeech;
